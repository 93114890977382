@use '../../../Sass/Incentive/Modules/' as *;

$banner: '../../../../Resources/Media/Incentive/Pages/Result/banner-result.png';
$icon: '../../../../Resources/Media/Incentive/Icons/icon-up.svg';
$arrow: '../../../../Resources/Media/Incentive/Icons/arrow.svg';

.result {
  overflow: hidden;

  &-banner {
    background: url('#{$banner}') no-repeat center center;
    background-size: cover;
    height: 320px;
  }

  &-bang {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: url('#{$icon}') center no-repeat;
      z-index: 1;
      width: 75px;
      height: 75px;
      top: 0;
      left: 0;
    }
  }

  select {
    border: 2px solid var(--c_blue);
    padding: 10px 30px;
    color: var(--c_blue);
    @include font-size(18px);
    font-weight: var(--f_semibold);
    line-height: 1.8;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('#{$arrow}') 94% center no-repeat;
    background-size: 24px;
    background-position-x: calc(100% - 20px);
  }

  &-table {
    &-div-table {
      width: 320px;
      border-top-left-radius: 22px;
      border-top-right-radius: 22px;
      h3 {
        @include font-size(24px);
        font-weight: var(--f_semibold);
        padding: 33px 0;
      }
    }
    table {
      width: 330px;
      border: 5px solid var(--c_white);
      thead {
        tr {
          th {
            @include font-size(19px);
            font-weight: var(--f_semibold);
            padding: 3.75px 0;
            border: 5px solid var(--c_white);
            &:last-child {
              border-right: none;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            @include font-size(22px);
            font-weight: var(--f_semibold);
            border: 5px solid var(--c_white);
            padding: 13.5px 0;
            text-align: center;
            &:last-child {
              border-right: none;
            }
            &:first-child {
              @include font-size(20px);
            }
          }
          &:last-child {
            td {
              @include font-size(40px);
              padding: 0;
              height: 57px;
              &:first-child {
                @include font-size(20px);
              }
            }
          }
        }
      }
    }
  }

  @include responsive(atMedium) {
    &-banner {
      height: 628px;
    }

    &-bang {
      &::after {
        top: -13px;
      }
    }

    &-table {
      &-div-table {
        width: 705px;
        border-top-left-radius: 45px;
        border-top-right-radius: 45px;
        h3 {
          padding: 25px 0;
        }
      }
      table {
        max-width: 720px;
        width: 100%;
        border: none;
        thead {
          tr {
            th {
              padding: 10px 0;
              border: 5px solid var(--c_white);
            }
          }
        }
        tbody {
          tr {
            td {
              width: 192px;
              padding: 11px 0;
              border: 5px solid var(--c_white);
              &:first-child {
                width: 126px;
              }
            }
          }
        }
      }
    }
  }
}
