@use "../../../Sass/Incentive/Modules/" as *;

.accelerators {
  display: block;
  position: relative;
  overflow: hidden;

  &-welcome {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;

    h1,
    h2 {
      @include font-size(24px);
      display: block;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: -1;
      }
    }

    h1 {
      @include font-size(24px);
      color: var(--c_yellow);
      padding: 20px;
      background-color: var(--c_blue);

      &::after {
        left: -500%;
        background-color: var(--c_blue);
      }
    }

    h2 {
      color: var(--c_blue);
      padding: 7px 0 5px 0px;
      background-color: var(--c_blue_light);
      line-height: 21px;

      &::after {
        left: -100%;
        background-color: var(--c_blue_light);
      }
    }
  }

  &-list {
    table {
      th,
      td {
        text-align: left;
        color: var(--c_white);
      }

      td {
        @include font-size(13px);
        font-weight: var(--font-medium);
        line-height: 1.2;
        padding-bottom: 10px;
      }

      th {
        @include font-size(18px);
        font-weight: var(--font-bold);
        padding-bottom: 10px;
      }
    }
  }

  &-maps {
    ul > li {
      @include font-size(18px);
      color: var(--c_blue);
      font-weight: var(--font-medium);
    }
  }

  @include responsive(atMedium) {
    &-welcome {
      grid-template-columns: 55% auto;
      align-items: center;

      h1,
      h2 {
        @include font-size(34px);
        border-top-right-radius: 50rem;
        border-bottom-right-radius: 50rem;
      }

      h1 {
        padding: 44px 180px;

        &::after {
          left: -995%;
          width: 100%;
        }
      }

      h2 {
        line-height: 34px;
        padding: 18px 20px 15px 0;

        &::after {
          right: 80px;
          width: 140%;
        }
      }
    }
    &-list {
      table {
        td {
          @include font-size(18px);
          padding-bottom: 5px;
        }

        th {
          @include font-size(22px);
        }
      }
    }
  }
}
