.manage-pharma {
  background-color: var(--c_light_gray);

  padding: 22px 17px;

  strong {
    font-weight: 600;
  }

  &__table-mobile {
    text-align: center;
    &__title,
    &__value {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #031e78;
      font-weight: 500;
    }
    &__title {
      background-color: #28cee3;
    }

    &__value {
      background-color: white;
    }
  }

  th {
    background-color: #28cee3;
    font-weight: 600;
    color: #031c7a;
    height: 41px;
  }

  td {
    background-color: white;
    font-weight: 500;
    color: #031c7a;
    height: 41px;
    font-weight: 600;

    text-align: center;
  }

  table {
    border-collapse: separate;
    border-spacing: 2px;
  }

  // table {
  //   thead {

  //   }
  // }
}
