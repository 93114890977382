@use '../Modules' as *;

form {
  * {
    appearance: none !important;
  }

  :focus {
    outline: none !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: var(--color-blue) !important;
  }

  input {
    border-radius: 24px;
  }
}
