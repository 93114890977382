main {
  .fourth-asset,
  .fifth-asset {
    height: 63px;
    width: 202px;
    // border-radius: 101px;
  }
  .first-asset {
    background-color: #031e78;

    // left: 150px;
  }
  .second-asset {
    background-color: #ffd800;
  }
  .third-asset {
    z-index: -1;

    width: 84px;
    position: absolute;
    background-color: #28cee3;
    border-top-left-radius: 110px;
    border-top-right-radius: 110px;
    right: 8px;
    bottom: -130px;
  }

  .variation-1 {
    .fourth-asset {
      background-color: #ffd800;
      z-index: -2;
    }

    .fifth-asset {
      background-color: #28cee3;
      z-index: -1;
    }
  }

  .variation-2 {
    .fifth-asset {
      background-color: #ffd800;
      z-index: -3;
    }
    .fourth-asset {
      background-color: #031e78;
      z-index: -2;
    }
  }
}
