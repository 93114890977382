@use "../Modules/" as *;

$right: "../../../../Resources/Media/Incentive/Icons/right.svg";
$rightMob: "../../../../Resources/Media/Incentive/Icons/right-mob.svg";
$left: "../../../../Resources/Media/Incentive/Icons/left.svg";
$leftMob: "../../../../Resources/Media/Incentive/Icons/left-mob.svg";

.slick-dots {
  display: none !important;
}

.slick-next,
.slick-prev {
  z-index: 9;
  top: 50%;
  &::before {
    opacity: 1;
  }
}

.slick-next {
  right: 28px;
}

.slick-prev {
  left: 0;
}

.slick-next {
  &::before {
    content: url("#{$rightMob}") !important;
    @include responsive(atMedium) {
      content: url("#{$right}") !important;
    }
  }
}
.slick-prev {
  &::before {
    content: url("#{$leftMob}") !important;
    @include responsive(atMedium) {
      content: url("#{$left}") !important;
    }
  }
}
