@use "../Modules" as *;

.title {
  height: 421px;
  &-col {
    width: 100%;

    h1 {
      @include font-size(34px);
      padding: 25px 0 25px 35px;
    }
  }

  @include responsive(atMedium) {
    height: 628px;
    &-col {
      border-top-right-radius: 90px;
      border-bottom-right-radius: 90px;
      max-width: 765px;

      h1 {
        @include font-size(56px);
        padding: 36px 0 36px 165px;
      }
    }
  }
}
