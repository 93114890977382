@use "../Modules" as *;

@keyframes widthAnimation {
  0% {
    width: 0px;
  }
  100% {
    width: 1051px;
  }
}

@keyframes floating {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes rotateBall {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes leftToRight {
  0% {
    transform: scale(-0.3) translateX(-100%);
  }
  100% {
    transform: scale(1) translateX(0);
  }
}

@keyframes rightToLeft {
  0% {
    transform: scale(0.2) translateX(100%);
  }
  100% {
    transform: scale(1) translateX(0);
  }
}

@keyframes fadeInFloating {
  0% {
    scale: 1.05;
    transform: translateY(10px);
  }
  100% {
    scale: 1;
    transform: translateY(0);
  }
}

@keyframes rotate {
  100% {
    transform: rotateY(-40deg) rotateX(-43deg);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
