@use "../../../Sass/Incentive/Modules/" as *;

.home {
  overflow: hidden;
  position: relative;

  &-slider {
    margin: 0 auto;
    max-width: 1140px;
  }
  &-welcome {
    position: relative;
    display: flex;
    flex-direction: column;

    h1 {
      @include font-size(24px);
      color: var(--c_blue);
      display: block;
      background-color: var(--c_yellow);
      text-align: center;
      padding: 20px 0;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: -500%;
        width: 100%;
        height: 100%;
        background-color: var(--c_yellow);
        top: 0;
        z-index: -1;
      }
    }
  }

  &-intro {
    h3 {
      @include font-size(18px);
      color: var(--c_blue);
      margin: 0 auto 0.8em auto;
    }

    iframe {
      max-width: 100%;
      overflow: hidden;
      max-height: 310px;
      margin-bottom: 2em;
    }
  }

  &-howto {
    &-slider {
      max-width: 1140px;
      margin: auto;

      .slick-next {
        right: 55px;
      }

      .slick-prev {
        left: 30px;
      }
    }
  }

  @include responsive(atMedium) {
    &-welcome {
      display: grid;
      grid-template-columns: 55% auto;
      gap: 0;
      align-items: center;

      h1 {
        @include font-size(34px);
        border-top-right-radius: 50rem;
        border-bottom-right-radius: 50rem;
        padding: 40px 20px;

        &::after {
          left: -1000%;
          width: 1000%;
        }
      }
    }

    &-intro {
      h3 {
        @include font-size(25px);
        grid-row: 1 / span 2;
        padding: 0 20px;
      }

      iframe {
        border-radius: 35px;
        margin-bottom: 0;
      }
    }
  }
}
