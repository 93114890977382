@use '../../Resources/Sass/Incentive/Modules/' as *;

.footer {
  width: 100%;
  background-color: var(--color-blue);
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    background-color: var(--c_blue);
    height: 78px;
    width: 81px;
    top: -60px;
    border-radius: 20px;
  }

  p {
    color: var(--color-white);
    text-align: center;
    font-family: var(--font-myriad);
    @include font-size(12px);
    max-width: 275px;
  }

  span {
    color: var(--color-aqua);
    @include font-size(11px);
    font-family: var(--font-myriad);
  }
  &__links {
    display: grid;

    background-color: #e8ebff;

    &__container {
      padding: 15px 0px;
      border-top: 1px solid #cccdd4;
      border-bottom: 1px solid #cccdd4;
      a {
        color: var(--c_gray_dark);
      }
    }

    img {
      width: 155px;
      height: 44px;
    }

    &__divider {
      height: 1px;
      width: 320px;
      background-color: #686868;

      opacity: 0.5;
    }

    span {
      font-size: 11px;
      color: var(--c_gray);
      text-align: center;
    }

    &__social-media {
      display: flex;
      justify-content: center;
      gap: 40px;
      order: 2;

      img {
        width: 20px;
        height: 20px;
      }
    }

    &__pages {
      display: flex;
      flex-direction: column;
      order: 3;
      padding-bottom: 20px;
      gap: 15px;

      a {
        color: var(--c_gray_dark);
        @include font-size(19px);
        font-weight: var(--font-medium);
        transition: all 0.3s ease-in-out;

        &:hover {
          color: var(--color-yellow);
        }
      }
    }
  }

  @include responsive(atMedium) {
    p {
      max-width: 100%;
    }
    &__links {
      &__divider {
        height: 1px;
        width: 1140px;
        background-color: #686868;

        opacity: 0.5;
      }
      // justify-content: center;
      // // grid-template-columns: 156px 515px 288px;
      // gap: 40px;
      // &__social-media {
      //   order: 3;
      // }
      // &__pages {
      //   gap: 0px;
      //   justify-content: space-around;
      //   order: 2;
      //   align-items: center;
      // }
    }
  }
}
