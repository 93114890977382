@use '../../../Sass/Incentive/Modules/' as *;

$bg-mobile: '../../../Media/Incentive/Pages/Howto/howto-bg-mob.jpg';
$bg-desktop: '../../../Media/Incentive/Pages/Howto/howto-bg.jpg';

.howto {
  &__img {
    background: url($bg-mobile) no-repeat center center;
    height: 480px;
    width: 979.63px;
  }

  &__title {
    background-color: var(--c_blue);
    font-size: 34px;
    color: var(--c_yellow);
    font-weight: var(--f_semibold);
    line-height: 38px;
  }

  &__container {
    h4 {
      line-height: 1;
      font-size: 18px;
      color: var(--c_blue_medium_darker);
      font-weight: var(--f_semibold);
      padding: 1.5em 2em;
    }

    &__bought-won {
      background-color: #031c7a;

      p {
        margin-top: 42px;
        font-size: 18px;
        font-weight: var(--f_semibold);
        color: var(--c_white);
        text-align: center;
        span {
          font-size: 33px;
          font-weight: var(--f_bold);
          color: var(--c_blue_light);
        }
      }

      &__products {
        background-color: #ffd800;
        color: #031c7a;
        height: 50px;
        font-size: 18px;
        border-radius: 36px;
        width: 100%;
        font-weight: var(--f_semibold);
      }
    }

    &__accelerators {
      background-color: #ffd800;
      padding: 88px 18px 18px 18px;
      p {
        color: var(--c_blue);
        font-weight: var(--f_semibold);
        line-height: 1;
      }

      img {
        width: 286px;
      }

      span {
        font-size: 18px;
        font-weight: var(--f_semibold);
        color: var(--c_blue);
        line-height: 1;
        margin-bottom: 22px;
      }

      &__confira {
        background-color: var(--c_blue);
        font-size: 18px;
        font-weight: var(--f_semibold);
        color: var(--c_yellow);
        width: 100%;
        line-height: 1;
        padding: 5px 0;
      }

      &__items-container {
        &__item {
          background-color: var(--c_blue);
          width: 227px;
          border-radius: 32px;
          position: relative;
          display: flex;
          justify-content: flex-start;
          padding-left: 67px;
          img {
            position: absolute;
            width: 61px;
            height: 63px;
            right: 75%;
            bottom: -10px;
          }

          p {
            font-weight: 400;
            color: #f7d64c;
            height: 34px;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  &__attention {
    background-color: #18cee3;
    padding: 41px 30px 18px 30px;
    &__card-img {
      height: 55px;
      background-color: var(--c_white);
      border-radius: 36px;
      width: 268px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      padding-left: 100px;

      div {
        position: absolute;
        width: 75px;
        height: 75px;
        right: 77%;
        bottom: -10px;
        background-color: #001c7a;
        border-radius: 50%;
      }

      p {
        font-size: 30px !important;
        color: #001c7a !important;
        font-weight: var(--f_semibold);
        margin-bottom: 0 !important;
      }
    }

    p {
      color: #001c7a;
      font-size: 20px;
      margin-bottom: 10px;
      span {
        font-weight: var(--f_semibold);
      }
    }

    &__distributor {
      background-color: #001c7a;
      border-radius: 30px;
      padding: 18px 35px;
      p {
        color: #18cee3;
        font-size: 30px;
        font-weight: var(--f_semibold);
        line-height: 35px;
      }

      span {
        color: var(--c_white);
        font-size: 18px;
        font-weight: var(--f_semibold);
      }
    }

    &__role {
      font-size: 14px !important;
      font-weight: lighter;
      margin-top: 23px;
    }
  }

  @include responsive(atMedium) {
    &__img {
      background: url($bg-desktop) no-repeat center center;
      height: 628px;
      width: 100%;
    }

    &__title {
      font-size: 56px;
      line-height: 1;
      border-top-right-radius: 64px;
      border-bottom-right-radius: 64px;
    }

    &__container {
      h4 {
        font-size: 30px;
        text-align: center;
      }

      &__bought-won {
    

        p {
          margin-top: 50px;
        }

        &__products {
          width: 352px;
        }
      }

      &__accelerators {
        padding: 110px 33px 25px 33px;
        max-width: 444px;

        p {
          font-size: 22px;
        }

        img {
          width: 335px;
        }

        &__confira {
          max-width: 352px;
        }

        &__items-container {
          &__item {
            width: 264px;
            padding: 4px 8px 4px 78px;

            img {
              width: 72px;
              height: 72px;
            }
          }
        }

        span {
          font-size: 22px;
        }
      }
    }

    &__attention {
      padding: 42px 18px;

      &__distributor {
        padding: 32px 30px 37px 30px;
        p {
          margin-bottom: 12px !important;
        }
      }

      p {
        margin-bottom: 0;
      }

      &__role {
        margin-top: 52px;
      }
    }
  }
}
