@use "../../../Sass/Incentive/Modules/" as *;

$banner: "../../../../Resources/Media/Incentive/Pages/Award/banner.png";
$bannerMobile: "../../../../Resources/Media/Incentive/Pages/Award/banner-mobile.png";
$premius: "../../../../Resources/Media/Incentive/Pages/Award/back-store.png";
$premiusMob: "../../../../Resources/Media/Incentive/Pages/Award/back-store-mob.png";

.award {
  padding-bottom: 200px;
  overflow: hidden;

  &-banner {
    background: url("#{$bannerMobile}") no-repeat center center;
    background-size: cover;
    height: 320px;
  }

  &-premius {
    &-container{
      max-width: 1079px;
      margin: 0 auto;
      display: grid;
      justify-content: center;
    }

    ul {
      li {
        color: var(--c_blue);
        @include font-size(18px);
        font-weight: var(--f_medium);
        span {
          color: var(--c_blue);
        }
      }
    }
  }

  @include responsive(atMedium) {
    padding-bottom: 80px;

    &-banner {
      background: url("#{$banner}") no-repeat center center;
      background-size: cover;
      height: 628px;
    }

    &-premius {
      &-container{
        grid-template-columns: auto 584px;
      }
    }

  }
}
