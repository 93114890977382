@use '../Modules' as *;


.no-logged{
  padding-top: 120px;


  @include responsive(atMedium){
    padding-top: 0;
    &::after{
      display: none;
    }
  }
}