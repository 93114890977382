@use '../../../Sass/Incentive/Modules/' as *;

$banner: '../../../Media/Incentive/Pages/Contact/bg.png';
$arrow: '../../../Media/Incentive/Icons/arrow.svg';
$iconTelPhone: '../../../Media/Incentive/Pages/Contact/contact-number.svg';
$iconWhatsapp: '../../../Media/Incentive/Pages/Contact/contact-whatsapp.svg';
$blue-arrow: '../../../Media/Incentive/Pages/Contact/arrowDetailBlue.png';

.contact {
  &-bang-phone {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: url('#{$iconTelPhone}') center no-repeat;
      z-index: 1;
      width: 57px;
      height: 57px;
      @include responsive(atExtraLargeSmall) {
        top: -7px;
      }
      top: 10px;
      left: 0;
    }
  }

  &-bang-mobile {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: url('#{$iconWhatsapp}') center no-repeat;
      z-index: 1;
      width: 57px;
      height: 57px;
      @include responsive(atExtraLargeSmall) {
        top: -7px;
      }
      top: 10px;
      left: 0;
    }
  }
  &-banner {
    background: url('#{$banner}') no-repeat center center;
    background-size: cover;
    height: 340px;
  }

  @include responsive(atMedium) {
    &-banner {
      height: 628px;
    }
  }

  .form-container {
    margin: 0 auto;
    &__inputs-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      input {
        width: 100%;

        &::placeholder {
          font-size: 0.875rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          color: var(--color-blue-100);
        }
      }

      label {
        font-size: 15px;
        // color: var(--color-blue);
      }

      textarea {
        display: flex;
        align-items: center;
        color: var(--c_white);
        justify-content: space-between;
        @include border-radius(28px);
        height: 55px;
        user-select: none;
        resize: none;
        font-weight: 300 !important;
        font-size: 14px;
        padding: 20px;
      }

      .btn-eye-pass {
        cursor: pointer;
        margin-right: 15px;
        svg {
          @include font-size(22px);
          color: var(--c_blue);

          &.fa-eye-slash {
            display: block;
          }
        }
      }

      select {
        line-height: 2.2;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('#{$arrow}') 94% center no-repeat;
        background-size: 24px;
        background-position-x: calc(100% - 20px);
        width: 100%;
        color: var(--c_white);
        font-weight: 300 !important;
        font-size: 14px;

        option {
          width: 100%;
          background-color: var(--color-white);
          color: var(--c_blue);
          font-weight: 500 !important;
        }
      }
    }
  }
}

.selected {
  color: #f7d64c !important;
  background-color: #04287f !important;
}

.question {
  // height: 52px;

  &::after {
    background-image: url($blue-arrow);
  }

  &__answer-container {
    transition: all 0.3s ease-in-out;

    height: 0;
    opacity: 0;

    &__open {
      height: max-content;
      opacity: 1;
    }
  }

  &__answer {
    border-top: 0.5px solid #e7e7e7;
  }

  &__icon-button {
    &__opened {
      transform: rotate(-180deg);
    }
  }
}

.rotate-180 {
  transform: rotate(180deg);
}
