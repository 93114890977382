@use "../../Resources/Sass/Incentive/Modules/" as *;

.points {
  position: relative;
  display: block;
  width: 320px;
  height: 310px;
  display: grid;
  align-items: center;
  margin: 0 auto;
  background-color: #031e7827;
  border-radius: 30px;

  p {
    text-align: center;
    text-transform: uppercase;
    @include font-size(22px);
    text-align: center;
    line-height: 1;
    color: var(--c_blue);
    strong {
      @include font-size(28px);
    }
  }
  
  .progress {
    &-point {
      width: 100%;
      max-width: 292px;
      margin: 3em auto 0 auto;
      border: solid 3px #1b2c7c;
      border-radius: 50rem;
      height: 35px;
      align-items: center;
      position: relative;
      padding: 0 5px;

      &::after {
        content: "";
        display: block;
        width: 96%;
        height: 100%;
        background-color: rgba(#1b2c7c, 0.5);
        z-index: 1;
        height: 19px;
        border-radius: 50rem;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
      }

      .progress-bar {
        height: 19px;
        border-radius: 50rem;
        background-color: #edd458;
        position: relative;
        z-index: 2;
        top: 5px;
      }
    }
  }

  @include responsive(atMedium) {
    width: 444px;
  }
}
