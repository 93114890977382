.create-password-one-email {
    background-color: var(--c_blue);
  
    h1 {
      color: var(--c_yellow);
      font-weight: var(--f_semibold);
    }
  
    p {
      font-weight: var(--f_medium);
      color: #fff;
      span {
        color: var(--c_yellow);
      }
    }
  }
  