@use '../Resources/Sass/Incentive/Modules/' as *;
@use '../Resources/Sass/Incentive/Components/' as *;

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../Styles/variables.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}

html,
body,
#root {
  font-family: 'canada-type-gibson';
  min-height: 100vh;
  width: 100%;
}

#root-container {
  overflow: hidden;
}

body {
  height: 100vh;

  &.menu-open {
    overflow: hidden;
  }

  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.errors-message {
  margin: 5px 0px 5px 20px;
  display: flex;
  align-items: baseline;
  img {
    margin-right: 5px;
    width: 20px;
    height: 15px;
  }
}

.popup {
  transition: 0.3s;
  &-open {
    background-color: rgba(39, 17, 21, 0.547);
  }
}

.loading {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999 !important;
  background-color: rgba(0, 0, 0, 0.7);
  display: block;
  height: 100%;

  .ico {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150px;
  }
}

.animate-spin {
  z-index: 123;
}

.disabled {
  border: 1px solid #6873d8 !important;
  background-color: #f0f2f7 !important;
  input {
    color: #596aa6;
    &::placeholder {
      color: #596aa6;
    }
  }
}

.error {
  color: var(--color-red-error);

  font-size: 12px !important;

  label {
    color: var(--color-red-error) !important;
  }

  input {
    color: var(--color-red-error);
    &::placeholder {
      color: var(--color-red-error);
    }
  }

  textarea {
    border: 1px solid var(--color-red-error) !important;
    &::placeholder {
      color: var(--color-red-error);
    }
  }

  .input-field {
    border: 1px solid var(--color-red-error) !important;
  }

  select {
    color: var(--color-red-error) !important;
  }
}

.yellow-error {
  color: #f7d64c;

  font-size: 12px !important;

  label {
    color: #f7d64c !important;
  }

  input {
    color: var(--color-red-error);
    &::placeholder {
      color: var(--color-red-error);
    }
  }

  textarea {
    border: 1px solid var(--color-red-error) !important;
    &::placeholder {
      color: var(--color-red-error);
    }
  }

  .input-field {
    border: 1px solid var(--color-red-error) !important;
  }

  select {
    color: #f7d64c !important;
  }
}

input,
select,
textarea,
label {
  font-family: 'canada-type-gibson', sans-serif;
}

input,
select,
textarea {
  width: 100%;
  @include border-radius(12px);
  padding: 10px 20px;
  line-height: 1;
  background-color: transparent;
  transition: all 0.3s ease;
  color: var(--color-blue-100);
  font-style: normal;
  font-weight: var(--font-semibold) !important;
  @include font-size(14px);

  &::placeholder {
    @include font-size(14px);
    color: var(--color-blue-100);
    font-style: normal;
    font-weight: var(--font-semibold) !important;
    width: 100%;
  }

  &::placeholder {
    padding-right: 0;
  }
}
