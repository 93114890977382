@use '../../Resources/Sass/Incentive/Modules/' as *;

$arrow: '../../Resources/Media/Incentive/Icons/arrow.svg';

.input-mask-disabled {
  color: var(--color-gray-100);
}

.input-mask-disabled::placeholder {
  color: var(--color-gray-100);
}

.input-field {
  border: 1px solid #1c2ccb !important;
}

.inputs-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  input {
    width: 100%;

    &::placeholder {
      font-size: 0.875rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: var(--color-blue-100);
    }
  }

  label {
    font-size: 1rem;
    color: var(--c_blue);
  }

  textarea {
    display: flex;
    align-items: center;
    border: 1px solid #1c2ccb !important;
    justify-content: space-between;
    @include border-radius(28px);
    height: 55px;
    user-select: none;
  }

  textarea {
    height: auto;
  }

  .btn-eye-pass {
    cursor: pointer;
    margin-right: 15px;
    svg {
      @include font-size(22px);
      color: var(--c_blue);

      &.fa-eye-slash {
        display: block;
      }
    }
  }

  select {
    line-height: 2.2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('#{$arrow}') 94% center no-repeat;
    background-size: 24px;
    background-position-x: calc(100% - 20px);
    width: 100%;
    color: var(--c_blue);
    font-weight: 500 !important;

    option {
      width: 100%;
      background-color: var(--color-white);
      color: var(--c_blue);
      font-weight: 500 !important;
    }
  }
}
