.edit-profile {
  background-color: var(--c_light_gray);

  padding: 22px 17px;
}

.style-checklist {
  li {
    span {
      color: #031e78;
    }
  }

  &-white {
    li {
      span {
        color: white;
        font-size: 14px;
      }
    }
  }
}
