@use '../../Resources/Sass/Incentive/Modules/' as *;

.button {
  color: var(--c_white);
  text-transform: uppercase;
  transition: 0.3s;
  width: 275px;
  min-height: 46px;

  &:hover {
    color: var(--c_blue);
    border: solid 2px var(--c_blue);
  }

  @include responsive(atMedium) {
    width: 335px;
  }

  &-light {
    border: solid 2px var(--c_blue_light);
  }
}

.outlined {
  text-decoration: underline;
  color: white;
  background-color: transparent !important;
  font-weight: var(--f_semibold);

  &:hover {
    color: white;
    border: none;
  }
}
