section {
  h1 {
    font-weight: var(--f_semibold);
    color: var(--c_blue);
  }
  .login {
    background-color: var(--c_blue);

    h2 {
      color: var(--c_yellow);
      font-weight: var(--f_semibold);
    }
  }

  .register {
    background-color: var(--c_blue_light);

    h2 {
      color: var(--c_blue);
      font-weight: var(--f_semibold);
    }
  }
}

.access {
  padding: 10px 0;
  box-shadow: 5px 5px 0 0 #031e78;
  p {
    font-weight: var(--f_semibold);
    line-height: 1.2;
    a {
      // font-weight: normal;
      text-decoration: underline;
    }
  }
}
