$check: '../../Resources/Media/Incentive/check.svg';

.container-input-checkbox {
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    font-weight: var(--f_medium);
  }

  a {
    text-decoration: underline;
  }

  div {
    display: flex;
  }

  .description-label {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}
