@use "../../../../Sass/Incentive//Modules/" as *;

.post {
  display: block;

  &-banner {
    height: 340px;
  }

  &-details {
    max-width: 286px;
    margin: 20px auto;
    p {
      color: var(--c_text);
      @include font-size(14px);
      margin-bottom: 15px;
    }
  }

  @include responsive(atMedium) {
    &-banner {
      height: 628px;
    }

    &-details {
      max-width: 904px;
      margin: 20px auto;
    }
  }
}
