@use '../../Resources/Sass/Incentive/Modules/' as *;

$menu-desktop: '../../Resources/Media/Common/menu-mobile.svg';

.header-desktop {
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 9999;
  transition: all 0.3s ease-in-out;
  height: 115px;
  padding: 0 15px;

  &__onScroll {
    height: 90px;
    box-shadow: none;
    background-color: transparent;

    background-color: var(--bg_blue);
    height: 92px;

    a {
      padding: 8px 5px !important;
    }

    .header-desktop__last-items {
      display: flex;
      flex-direction: row !important;
      gap: 8px;
      justify-content: flex-end;

      &__hello-card {
        background-color: var(--c_blue);
        color: var(--c_white);
        padding: 8px 15px !important;
        font-weight: 700;
        border-radius: 30px;
        font-size: 14px;
        transition: all 0.3s ease-in-out;
        position: static;

        &__details {
          a {
            padding: 0 !important;
          }
          padding: 0px 15px 7px 15px !important;
        }

        img {
          margin-left: 10px;
        }
      }
    }

    .header-desktop__img-desktop {
      background-color: var(--c_blue);
      padding: 0 18px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      max-width: 115px !important;
      margin-right: 0 !important;
    }
  }

  a {
    background-color: var(--c_blue);
    color: var(--c_white);
    padding: 8px 18px;
    font-weight: var(--f_semibold);
    border-radius: 30px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: var(--c_white);
      color: var(--c_blue) !important;
      transition: 0.5s;
    }
  }

  .link-dropdown {
    &:hover {
      color: #28cee3 !important;
    }
  }

  .header-desktop__img-desktop {
    background-color: var(--c_blue);
    padding: 0 18px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    max-width: 114px;
    max-height: 127px;
    margin-right: 1.5rem !important;
  }

  .header-desktop__portal-points {
    background-color: var(--c_white) !important;
    color: var(--c_blue) !important;
    font-weight: var(--f_semibold);
    p {
      color: var(--c_text);
      span {
        color: var(--c_blue);
      }
    }
  }

  .header-desktop__items {
    width: 1000px !important;
    min-width: 872px;
  }

  .header-desktop__last-items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-end;

    &__hello-card {
      background-color: var(--c_blue);
      color: var(--c_white);
      padding: 8px 18px;
      font-weight: var(--f_medium);
      border-radius: 30px;
      font-size: 14px;
      transition: all 0.3s ease-in-out;
      // width: 110px;
      z-index: 99;

      img {
        width: 15px;
      }

      &__details {
        background-color: var(--c_blue);
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        padding: 27px 15px 25px 15px;

        ul {
          li {
            margin-bottom: 10px;
          }
        }

        a {
          padding: 0;

          color: var(--c_white);
          font-weight: var(--f_medium);
          font-size: 16px;

          &:hover {
            background-color: var(--c_blue);

            color: #28cee3;
          }
        }

        p {
          color: var(--c_white);
          font-weight: var(--f_medium);
          span {
            color: #28cee3;
            font-weight: var(--f_semibold);
          }
        }
      }
    }
  }
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  box-shadow: 2px 2px 4px gray;
  background-color: white;
  z-index: 99;
  height: 60px;

  &-wrapper {
    @include container;
    padding: 0 23px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-logo {
    grid-column: 1;
    grid-row: 1;
    background-color: var(--c_blue);

    position: absolute;
    background-color: var(--c_blue);
    padding: 20px 10px 13px 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 85px !important;
    height: 85px;
    margin-right: 0 !important;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    // height: 100px;

    a {
      img {
        width: 164px;
        height: 53px;
      }
    }
  }

  &-menu {
    grid-column: 3;
    grid-row: 1;

    &__select-container {
      height: 250px !important;
      background-color: #28cee3;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .header__select-container__logo {
        background-color: var(--c_blue);
        position: absolute;
        background-color: var(--c_blue);
        padding: 50px 10px 13px 10px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        width: 79px !important;
        margin-right: 0 !important;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
        // height: 100px;

        a {
          img {
            width: 200px;
          }
        }
      }

      &__details {
        background-color: var(--c_blue);
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        padding: 20px 15px 25px 15px;

        a {
          padding: 0;
          line-height: 1;
          color: var(--c_white);
          font-weight: var(--f_semibold);
          font-size: 16px;
        }

        p {
          line-height: 1;
          color: var(--c_white);
          font-weight: var(--f_semibold);
          span {
            color: #28cee3;
          }
        }
      }

      &__collapse {
        background-color: var(--c_blue);
        border-radius: 30px;
        width: 255px;
        color: var(--c_white);
        font-size: 16px;
        font-weight: 700;
        // padding: 12px 20px;
        display: flex;
        justify-content: space-between;

        padding: 0 12px;
        margin-top: 70px;
        transition: all 0.3s ease-in-out;
        z-index: 999;

        p {
          font-size: 16px !important;
          margin: 0 !important;
          line-height: 1;
        }

        div {
          p,
          a {
            span {
              color: #28cee3;
            }
            font-weight: var(--f_semibold);
          }
        }
      }
    }

    &-toggle {
      cursor: pointer;
      position: relative;
      z-index: 5;

      img {
        height: 27px !important;
        width: 27px !important;
      }

      &.open {
        div {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            background-color: var(--color-black-900);
          }

          &:nth-child(1) {
            top: 10px;
            transform: rotate(135deg);
          }
          &:nth-child(2) {
            opacity: 0;
            left: -60px;
          }
          &:nth-child(3) {
            top: 10px;
            transform: rotate(-135deg);
          }
        }
      }
    }

    &-items {
      position: fixed;
      width: 90%;
      height: 100%;
      right: 0;
      top: 0;
      z-index: 4;
      background: var(--c_blue);
      transform: translateX(100%);
      transition: transform 0.15s ease-in-out;

      p {
        color: var(--c_white);
        font-size: 11px;
        text-align: center;
        margin-bottom: 27px;
      }

      &-selos {
        p {
          background-color: var(--color-white);
          border-radius: 12px;
          color: var(--color-blue);
          padding: 5px;
        }
      }

      &-profile {
        background-color: var(--color-gray-background-800);
        width: 100%;
        max-width: 211px;
        margin: 38.4px auto 38px auto;
        display: block;
        padding: 5px 15px;
        border-radius: 10px;
        overflow: hidden;
      }

      &.open {
        transform: translateX(0);
        overflow-y: scroll;
        overflow-x: hidden;
      }

      nav {
        display: block;
        width: 100%;

        ul {
          display: flex;
          flex-direction: column;
          // gap: 8px;
          :last-child {
            margin-bottom: 40px;
          }

          li {
            padding: 8px;
            padding-left: 32px;

            a {
              font-weight: var(--f_semibold);
              @include font-size(16px);
              transition: all 0.3s ease-in-out;
              color: var(--color-white);
            }
            &.active,
            &:hover {
              a {
                color: #28cee3;
                transition: 0.1s;
              }
            }
          }
        }
      }
    }
  }

  &-profile {
    grid-column: 2;
    grid-row: 1;
    line-height: 1.1;

    &-name {
      background-color: var(--color-gray-background-800);
      width: 100%;
      max-width: 117px;
      color: var(--color-white);
      padding: 2px 15px;
      border-radius: 10px;
      display: block;
      margin: 0 auto;
      line-height: 1.2;
      @include font-size(14px);
      strong {
        @include font-size(16px);
        font-weight: var(--font-montserrat-bold);
      }
    }
  }

  @include responsive(atMedium) {
    height: 100px;
    &-wrapper {
      display: grid;
      grid-template-columns: 211px 1fr 211px;
      margin: 5px auto 0 auto;
    }

    &-logo {
      grid-column: 2;
      width: max-content;
      margin: auto;
    }

    &-menu {
      grid-column: 1;

      &-toggle {
        width: 50px;
        height: 71px;
        background: url($menu-desktop) no-repeat center center;
        background-size: contain;

        div {
          display: none;
        }
      }

      &-items {
        transform: translateX(-100%);
        max-width: 583px;
        z-index: 99;

        &.open {
          overflow-y: auto;
        }

        p {
          color: var(--c_white);
        }

        nav {
          max-width: 425px;
          margin: 15px auto 0 140px;
          background-color: var(--c_blue);

          ul {
            li {
              margin-top: 30px;
              margin-left: 0;

              a {
                @include font-size(34px);
              }
            }
          }
        }
      }
    }

    &-profile {
      grid-column: 3;
      grid-row: 1;

      &-name {
        max-width: 211px;
        height: 79px;
      }
    }
  }
}

.selected-link {
  background-color: var(--color-white);
  a {
    color: var(--c_blue) !important;
  }
}
