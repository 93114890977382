// --media: '/media/incentive/';

:root {
  --color-black-900: #000000;
  --color-white: #ffffff;

  --color-red-error: #cc0000;

  --font-light: 200;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;

  --c_blue: #031e78;
  --c_blue_semi: #00287f;
  --c_blue_medium: #2442a7;
  --c_blue_medium_darker: #04287f;
  --c_blue_light: #18cee3;
  --c_yellow: #ffd800;
  --c_black: #000;
  --c_white: #fff;
  --c_light_gray: #dbdfec;
  --c_gray: #585858;
  --c_gray_dark: #686868;
  --c_text: #606060;
  --c_red: #ff0000;

  --colors: (
    c_blue: #031e78,
    c_blue_medium: #2442a7,
    c_blue_light: #18cee3,
    c_yellow: #ffd800,
    c_yellow_light: #f7d64c,
    c_black: #000,
    c_white: #fff,
    c_gray: #585858
  );

  --bg_blue: #031e78;
  --bg_blue_medium: #2442a7;
  --bg_blue_light: #18cee3;
  --bg_yellow: #ffd800;
  --bg_white: #fff;
  --bg_table: #f3f5ff;
  --bg_input_form: #8598c2;

  --backgrounds: (
    bg_blue: #031e78,
    bg_blue_medium: #2442a7,
    bg_blue_light: #18cee3,
    bg_yellow: #ffd800,
    bg_yellow_light: #f7d64c,
    bg_black: #000,
    bg_white: #fff,
    bg_table: #f3f5ff
  );

  // Fonts
  --f_light: 300;
  --f_normal: 400;
  --f_medium: 500;
  --f_semibold: 600;
  --f_bold: 700;
  --f_extrabold: 800;
  --f_black: 900;
}
