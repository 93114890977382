@use "../../../Sass/Incentive/Modules/" as *;

$banner: "../../../../Resources/Media/Incentive/Pages/Blog/blog.png";

.blog {
  &-banner {
    background: url("#{$banner}") no-repeat center center;
    background-size: cover;
    height: 340px;
  }

  @include responsive(atMedium) {
    &-banner {
      height: 628px;
    }
  }
}
